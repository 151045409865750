import axios from "axios";
import { BRAND_BASE_URL, STORE_BASE_URL } from "./BaseURL";

export const signIn = ({ username, password, source, version }) => {
  return axios({
    method: "post",
    url: BRAND_BASE_URL + "/brand/api/auth/signIn",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    data: JSON.stringify({ username, password, source, version }),
  });
};

export const userProfile = (token) => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/brand/api/secure/v1/user-profile", // for android use
    headers: {
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Content-Type": "application/json",
    },
  });
};

export const brandProfile = (token) => {
  return axios({
    method: "get",
    url: BRAND_BASE_URL + "/brand/api/secure/v1/brand-profile", // for android use
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  });
};

export const getStoreUser = (id, token) => {
  return axios({
    method: "get",
    url: STORE_BASE_URL + "/store/api/secure/v1/storeUser/" + id,
    // headers: getHeaders2(),
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  });
};

export const storePickupRequest = (token, data) => {
  return axios({
    method: "post",
    // url: BRAND_BASE_URL + "/brand/api/secure/v1/brand-profile", // for android use
    url: "https://api.dhobicart.in/store/api/secure/v1/web-pickup/97OIT/store-pickup",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    data: JSON.stringify(data),
  });
};