import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { getQrCodeImages2, storeOrders } from "../services/Orders";
import { getUserData } from "../utils/localStorage";
import { formatDate } from "../utils/DateUtil";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

const StoreTagList = () => {
  const userData = getUserData();
  const navigate = useNavigate();
  let store = userData.storeData;
  const storeId = store?.id;
  const [loading, setLoading] = useState(false);

  const [orderData, setOrderData] = useState([]);
  const [orderQrCodeImage, setOrderQrCodeImage] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: "contains" },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visible, setVisible] = useState(false);

  const fetchStoreOrders = (data) => {
    setLoading(true);
    storeOrders(storeId, data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setOrderData(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    const data = {
      storeId: storeId,
      statusIn: [
        "BOOKED",
        "INPROCESS",
        "PROCESSED",
        "UNPROCESSED",
        "IN_WAREHOUSE",
        "OUT_FOR_DELIVERY",
        "DELAY_STATUS",
      ],
      recordType: "OrderOn",
      startDate: "2024-01-01T00:00:00.000Z",
      endDate: new Date(),
    };
    fetchStoreOrders(data);
  }, []);

  const fetchQrCode = (storeCustomerId, storeId, bookingOrderId, type) => {
    getQrCodeImages2(storeCustomerId, storeId, bookingOrderId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;

          // Extract values from the response object
          const qrCodeImages = Object.values(Res);
          type === "view"
            ? setVisible(true)
            : navigate("/garment-tag", {
                state: {
                  qrCodeImages,
                  type: type,
                },
              });
          setOrderQrCodeImage(qrCodeImages);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <InputText
          iconPosition="left"
          placeholder="Search"
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = renderHeader();

  return (
    <div>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                </h3>
              </div>
            ) : orderData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>Garment Tag </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <DataTable
                        value={orderData}
                        paginator
                        stripedRows
                        showGridlines
                        rows={10}
                        filters={filters}
                        loading={loading}
                        dataKey="id"
                        header={header}
                        emptyMessage="No customers found."
                        globalFilterFields={[
                          "invoiceNo",
                          "orderNo",
                          "customerName",
                          "customerMobile",
                        ]}
                      >
                        <Column
                          header="Booking Date"
                          body={(data) => formatDate(data.createdDate)}
                        />
                        <Column
                          header="Delivery Date"
                          body={(data) => formatDate(data.deliveryOn)}
                        />
                        <Column
                          header="Invoice No."
                          filterField="invoiceNo"
                          body={(data) => data.invoiceNo}
                        />
                        <Column
                          header="Order No."
                          filterField="orderNo"
                          body={(data) => data.orderNo}
                        />
                        <Column
                          field="status"
                          header="Customer Name"
                          filterField="customerName"
                          body={(data) => data.customerName}
                        />
                        <Column
                          field="activity"
                          header="Mobile Number"
                          filterField="customerMobile"
                          body={(data) => data.customerMobile}
                        />
                        <Column
                          field="activity"
                          header="Tag Print"
                          showFilterMatchModes={false}
                          body={(data) => (
                            <span>
                              <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                  fetchQrCode(
                                    data.storeCustomerId,
                                    storeId,
                                    data.id,
                                    "print"
                                  )
                                }
                              >
                                <i
                                  className="pi pi-print"
                                  style={{ color: "green", fontSize: "20px" }}
                                ></i>
                              </button>
                            </span>
                          )}
                        />
                        <Column
                          field="activity"
                          header="Tag View"
                          showFilterMatchModes={false}
                          body={(data) => (
                            <span>
                              <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                  fetchQrCode(
                                    data.storeCustomerId,
                                    storeId,
                                    data.id,
                                    "view"
                                  )
                                }
                              >
                                <i
                                  className="pi pi-qrcode"
                                  style={{ color: "blue", fontSize: "20px" }}
                                ></i>
                              </button>
                            </span>
                          )}
                        />
                      </DataTable>
                    </div>
                    <div>
                      <Dialog
                        header="Garment Tag"
                        visible={visible}
                        style={{ width: "30vw" }}
                        onHide={() => setVisible(false)}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {orderQrCodeImage?.map((item, index) => (
                            <Image
                              key={index}
                              src={`data:image/jpeg;base64,${item}`}
                            />
                          ))}
                        </div>
                      </Dialog>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No Garment Tag Found</h3>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StoreTagList;
