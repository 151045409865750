import axios from "axios";
import getHeaders from "../constants/headers";
import { PRICElIST_BASE_URL } from "./BaseURL";

export const getBrandPriceCode = (brandId) => {
  return axios({
    method: "get",
    url: PRICElIST_BASE_URL + "/api/secure/v1/brand/" + brandId + "/price-code",
    headers: getHeaders(),
  });
};

export const postBrandPriceListConfigData = (brandId, data) => {
  return axios({
    method: "post",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v2/brand/" +
      brandId +
      "/price-list-config-data",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const postPriceListConfig = (brandId, data) => {
  return axios({
    method: "post",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/brand/" +
      brandId +
      "/price-list-config",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};