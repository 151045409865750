import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Row,
  Stack,
} from "react-bootstrap";
import "./index.css";
import { Link } from "react-router-dom";
import { getUserData } from "../utils/localStorage";
import StoreTagList from "./StoreTagList";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { formatDate } from "../constants/FormateDate";

const Dashboard = () => {
  const userData = getUserData();
  let brand = userData.brandProfileData;
  let user = userData.userProfileData;

  console.log("brand", brand);
  console.log("user", user);

  const brandOfferModal = userData.brandProfileData.brandOffModel;

  const [visible, setVisible] = useState(false);

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        // className=" backgroundColor-white"
        style={{
          // backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
          backgroundColor: "#FBFBFB",
        }}
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              style={{ width: "120px" }}
              src="/Img/DhobiCart.png"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              {/*<Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>*/}
              <button
                type="button"
                class="btn"
                onClick={() => setVisible(true)}
              >
                <i
                  className="pi pi-user"
                  style={{
                    fontSize: "2rem",
                    color: "#2b70d7",
                    cursor: "pointer",
                  }}
                ></i>
              </button>
              <Nav.Link className="nav-link-design" href="/logout">
                <i
                  className="pi pi-power-off"
                  style={{
                    fontSize: "2rem",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        {/* <Card
          style={{
            backgroundColor: "#1752AA",
            color: "white",
            marginTop: 20,
            textAlign: "center",
          }}
        >
          <Card.Body>
            <Row>
              <Col>
                <h5>Brand Name : {brand.name}</h5>
              </Col>
              <Col>
                <h5>Mobile Number : {brand.mobileNo}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>User Name : {user.firstName + " " + user.lastName}</h5>
              </Col>
              <Col>
                <h5>Mobile Number : {user.mobileNo}</h5>
              </Col>
            </Row>
          </Card.Body>
        </Card>*/}
        {user.role === "ROLE_ADMIN" ? (
          <React.Fragment>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginRight: 25,
                }}
              >
                <Link
                  to="/tariff-list-selection"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "280px",
                    height: "280px",
                    backgroundColor: "#fff",
                    padding: 25,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                  }}
                >
                  <h4
                    style={{
                      width: "150px",
                      textAlign: "center",
                      color: "#1752AA",
                      margin: 20,
                    }}
                  >
                    Tariff Management
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "120px" }}
                      src="/Img/rupee_1.png"
                      alt="logo"
                    />
                  </div>
                </Link>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  marginLeft: 25,
                }}
              >
                <Link
                  to="/package-list-selection"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    height: "280px",
                    backgroundColor: "#fff",
                    padding: 25,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    margin: 5,
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <h4
                    style={{
                      textAlign: "center",
                      color: "#1752AA",
                      margin: 20,
                    }}
                  >
                    Package Garment Management
                  </h4>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "90px" }}
                      src="/Img/box.png"
                      alt="logo"
                    />
                  </div>
                </Link>

                {brandOfferModal && (
                  <Link
                    to="/offer-management"
                    style={{
                      textDecoration: "none",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "280px",
                      height: "280px",
                      backgroundColor: "#fff",
                      padding: 25,
                      borderRadius: 20,
                      cursor: "pointer",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      transition: "0.3s",
                      margin: 5,
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        color: "#1752AA",
                        margin: 20,
                      }}
                    >
                      Offer Garment Management
                    </h4>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "100px" }}
                        src="/Img/special-tag.png"
                        alt="logo"
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <StoreTagList />
        )}
        <div>
          <Dialog
            header="Profile Data"
            visible={visible}
            style={{ flex: 0.3, display: "flex", justifyContent: "center" }}
            onHide={() => setVisible(false)}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#000",
                }}
              >
                <h4>Brand Name</h4>
              </div>
              <div
                style={{
                  flex: 1,
                  color: "#1752AA",
                }}
              >
                <h5> : {brand.name}</h5>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#000",
                }}
              >
                <h4>User Name</h4>
              </div>
              <div
                style={{
                  flex: 1,
                  color: "#1752AA",
                }}
              >
                <h5> : {user.firstName + " " + user.lastName}</h5>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#000",
                }}
              >
                <h4>Mobile No.</h4>
              </div>
              <div
                style={{
                  flex: 1,
                  color: "#1752AA",
                }}
              >
                <h5> : {user.mobileNo}</h5>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#000",
                }}
              >
                <h4>Register On</h4>
              </div>
              <div
                style={{
                  flex: 1,
                  color: "#1752AA",
                }}
              >
                <h5> : {formatDate(user.createdDate)}</h5>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#000",
                }}
              >
                <h4>Role</h4>
              </div>
              <div
                style={{
                  flex: 1,
                  color: "#1752AA",
                }}
              >
                <h5> : {user?.role?.replace(/_/g, " ")}</h5>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#000",
                }}
              >
                <h4>User Id</h4>
              </div>
              <div
                style={{
                  flex: 1,
                  color: "#1752AA",
                }}
              >
                <h5> : {user.userLoginId}</h5>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  color: "#000",
                }}
              >
                <h4>Account Status</h4>
              </div>
              <div
                style={{
                  flex: 1,
                  color: "#1752AA",
                }}
              >
                <h5> : {user.enabled ? "Active" : "InActive"}</h5>
              </div>
            </div>
          </Dialog>
        </div>
      </Container>
      <footer style={{ backgroundColor: "#FBFBFB" }}>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="flex social-media-div" style={{ marginLeft: 30 }}>
                <Link
                  to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
                  target="_blank"
                >
                  <i
                    className="pi pi-youtube"
                    style={{
                      fontSize: "2rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.instagram.com/dhobicart/?hl=en"
                  target="_blank"
                >
                  <i
                    className="pi pi-instagram"
                    style={{
                      fontSize: "2rem",
                      color: "#d62976",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/71974228/admin/dashboard/"
                  target="_blank"
                >
                  <i
                    className="pi pi-linkedin"
                    style={{
                      fontSize: "2rem",
                      color: "#0072b1",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.facebook.com/oneindiatechno"
                  target="_blank"
                >
                  <i
                    className="pi pi-facebook"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
                  target="_blank"
                >
                  <i
                    className="pi pi-android"
                    style={{
                      fontSize: "2rem",
                      color: "#a4c639",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link to="tel:+91 9319518337" target="_blank">
                  <i
                    className="pi pi-phone"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
              </Nav>
            </Col>
            {/*<Col>
              <Link
                to="https://www.oneindiatech.com/?page_id=75"
                target="_blank"
                style={{ textDecoration: "none", fontSize: 20 }}
              >
                Contact Us!
              </Link>
              <p>Phone : +91 9319518337</p>
            </Col>*/}
          </Row>
          <Row>
            <Col>
              <div className="text-center p-3" style={{ fontSize: 16 }}>
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a className="text-dark" href="https://oneindiatech.com/">
                  One India Technologies Pvt. Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Dashboard;
