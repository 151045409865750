import axios from "axios";
import getHeaders from "../constants/headers";
import { PRICElIST_BASE_URL, STORE_BASE_URL } from "./BaseURL";

export const getBrandOffer = (brandId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/offer/brand/" +
      brandId +
      "/brand-offer",
    headers: getHeaders(),
  });
};

export const postBrandPriceList = (brandId, data) => {
  return axios({
    method: "post",
    url: PRICElIST_BASE_URL + "/api/secure/v1/brand/" + brandId + "/price-list",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getBrandSingleOffer = (brandId, offerId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/offer/brand/" +
      brandId +
      "/brand-offer/" +
      offerId,
    headers: getHeaders(),
  });
};


export const updateBrandOffer = (brandId, data) => {
  return axios({
    method: "put",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/offer/brand/" +
      brandId +
      "/brand-offer",
    // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};