import axios from "axios";
import { STORE_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const storeOrders = (storeId, data) => {
  return axios({
    method: "post",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-order-by-status",
    // for android use
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getQrCodeImages2 = (id, storeId, orderId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-customer/ " +
      id +
      "/order/" +
      orderId +
      "/qr-code/png",
    headers: getHeaders(),
  });
};

export const getQrCodeTextLabel = (id, storeId, orderId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/store/" +
      storeId +
      "/store-customer/ " +
      id +
      "/order/" +
      orderId +
      "/qr-code-text-print",
    headers: getHeaders(),
  });
};