export const BRAND_BASE_URL = "https://dhobicart.in"; // 8089 FOR BRAND
export const STORE_BASE_URL = "https://dhobicart.in"; // 8092 FOR STORE
export const CONSUMER_BASE_URL = "https://dhobicart.in/consumer"; // 8093 FOR CONSUMER
export const PRICElIST_BASE_URL = "https://dhobicart.in/pricelist"; // 8098 FOR PRICE LIST



// http://dhobicart.in:8761/
// production use
// export const BRAND_BASE_URL = "https://api.dhobicart.in"; // 8089 FOR BRAND
// export const STORE_BASE_URL = "https://api.dhobicart.in"; // 8092 FOR STORE
// export const CONSUMER_BASE_URL = "https://api.dhobicart.in/consumer"; // 8093 FOR CONSUMER
// export const PRICElIST_BASE_URL = 'https://api.dhobicart.in/pricelist'; // 8098 FOR PRICE LIST

// development use
// export const BRAND_BASE_URL = "https://dev.dhobicart.in"; // 8089 FOR BRAND
// export const STORE_BASE_URL = "https://dev.dhobicart.in"; // 8092 FOR STORE
// export const CONSUMER_BASE_URL = "https://dev.dhobicart.in/consumer"; // 8093 FOR CONSUMER
// export const PRICElIST_BASE_URL = 'https://dev.dhobicart.in/pricelist'; // 8098 FOR PRICE LIST
