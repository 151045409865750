import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState } from "react";
import "./index.css";
import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const PriceListOriginal = () => {

  //  const priceListDTO = state.priceListDTO;
  const Garments = [
    {
      name: "Shirt",
      categories: ["Men", "Women", "Kid", "Other"],
    },
    {
      name: "Pant",
      categories: ["Men", "Women", "Kid", "Other"],
    },
    {
      name: "Trouser",
      categories: ["Men", "Women", "Kid", "Other"],
    },
    {
      name: "Blezzer",
      categories: ["Men", "Women", "Kid", "Other"],
    },
    {
      name: "Coat",
      categories: ["Men", "Women", "Kid", "Other"],
    },
    {
      name: "T-shirt",
      categories: ["Men", "Women", "Kid", "Other"],
    },
    {
      name: "Undergarment",
      categories: ["Men", "Women", "Kid", "Other"],
    },
    {
      name: "Saree",
      categories: ["Women", "Kid", "Other"],
    },
  ];

  const Services = [
    {
      name: "Dry Clean",
    },
    {
      name: "Laundry",
    },
    // {
    //   name: "Wash & Iron",
    // },
    // {
    //   name: "Rafu",
    // },
    // {
    //   name: "Shoe Cleaning",
    // },
    // {
    //   name: "Stain",
    // },
    // {
    //   name: "Button",
    // },
    // {
    //   name: "Stitches",
    // },
    
  ];

  const [formData, setFormData] = useState({});
  const [submitted, setSubmitted] = useState(false);

   const handleInputChange = (
     event,
     garmentName,
     categoryName,
     serviceName
   ) => {
     const value = event.target.value;
     setFormData((prevFormData) => ({
       ...prevFormData,
       [`${garmentName}-${categoryName}-${serviceName}`]: value,
     }));
   };

  const handleSubmit = () => {
    const submittedData = Garments.reduce((acc, garment) => {
      const garmentData = {};
      garment.categories.forEach((category) => {
        const categoryObj = Object.fromEntries(
          Services.map(({ name }) => [
            name,
            formData[`${garment.name}-${category}-${name}`] || 0,
          ])
        );
        garmentData[category] = categoryObj;
      });
      acc[garment.name] = garmentData;
      return acc;
    }, {});

    setSubmitted(true);
  };

  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <NavDropdown title="Setting" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col>
            <h3>Price List - Alpha</h3>
          </Col>
        </Row>
        <main id="main" className="main mb-3">
          <section className="section">
            <table className="table">
              <thead>
                <tr>
                  <th>Garments</th>
                  <th>Category</th>
                  {Services.map((service, index) => (
                    <th key={index}>{service.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Garments.map((garment, garmentIndex) =>
                  garment.categories.map((category, categoryIndex) => (
                    <tr key={`${garment.name}-${category}`}>
                      {categoryIndex === 0 && (
                        <td rowSpan={garment.categories.length}>
                          {garment.name}
                        </td>
                      )}
                      <td>{category}</td>
                      {Services.map((service, serviceIndex) => (
                        <td key={serviceIndex}>
                          <InputText
                            type="text"
                            style={{ width: 50, height: 10 }}
                            id={`${garment.name}-${category}-${service.name}`}
                            name={`${garment.name}-${category}-${service.name}`}
                            value={
                              formData[
                                `${garment.name}-${category}-${service.name}`
                              ] || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                garment.name,
                                category,
                                service.name
                              )
                            }
                            required
                          />
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="col-md-12 text-center">
              <Button
                label="Submit"
                type="submit"
                onClick={handleSubmit}
                className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
              />
            </div>
            {submitted && (
              <div className="col-md-12 text-center mt-3">
                <p>Form submitted successfully!</p>
              </div>
            )}
          </section>
        </main>
        <div style={{color:'red'}}>
          <h2>
            Note : Blank Fields are considered as 0 (zero) price. Services with 0 (zero) price
            will not be displayed in price list{" "}
          </h2>
        </div>
      </Container>
    </div>
  );
};

export default PriceListOriginal;

