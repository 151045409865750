import React, { useEffect, useState } from "react";
import { getUserData } from "../utils/localStorage";
import {
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  getBrandPriceCode,
  postBrandPriceListConfigData,
} from "../services/getPriceCode";
import { errorMessage, successMessage } from "../utils/alertMessage";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const PackageListSelection = () => {
  const navigate = useNavigate();

  const userData = getUserData();
  let brandId = userData.brandProfileData.id;
  const [brandPackageList, setBrandPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
   const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    appServiceList: "",
    serviceUnit: "",
    priceId: "",
  });
  const [errors, setErrors] = useState({
    appServiceList: "",
    serviceUnit: "",
    priceId: "",
  });

  const fetchGetBrandPriceCode = () => {
    getBrandPriceCode(brandId)
      .then((response) => {
        if (!response.data.error) {
          const res = response.data;
          // const priceList = res.prices;
          const filteredData = res.packages.filter(
            (item) => item.status === true
          );
          const packageList = filteredData.map((item) => ({
            label: item.name,
            value: item.price.id,
            appService: item.appService, // Assuming this field exists in your API response
            serviceUnit: item.serviceUnit, // Assuming this field exists in your API response
          }));
          setBrandPackageList(packageList);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

 

  useEffect(() => {
    fetchGetBrandPriceCode();
  }, []);

  const handlePackageChange = (e) => {
    const selectedPackage = brandPackageList.find(
      (pkg) => pkg.value === e.target.value
    );
    setSelectedPackage(selectedPackage);
    setInput({
      ...input,
      priceId: e.target.value,
      appServiceList: selectedPackage?.appService ?? "",
      serviceUnit: selectedPackage?.serviceUnit ?? "",
    });
  };

 const validateForm = () => {
   let isValid = true;
   const newErrors = { ...errors };

   if (input.priceId === "") {
     newErrors.priceId = "Please select any Package List";
     isValid = false;
   } else {
     newErrors.priceId = "";
   }
   setErrors(newErrors);
   return isValid;
 };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitButtonPressed(true);
    if (validateForm()) {
      let data = {
        appServiceList: [input.appServiceList],
        serviceUnit: input.serviceUnit,
        priceId: input.priceId,
      };
      postBrandPriceListConfigData(brandId, data)
        .then((response) => {
          if (!response.data.error) {
            if (response.data.success === false) {
              errorMessage(response.data.message);
              setSubmitButtonPressed(false);
            } else {
              successMessage(response.data.message);
              const selectedPrice = brandPackageList.find(
                (item) => item.value === input.priceId
              );
              setSubmitButtonPressed(false);
              navigate("/package-list", {
                state: {
                  ...response.data,
                  selectedPriceCode: selectedPrice ? selectedPrice.label : "",
                  type: "package",
                },
              });
            }
          } else {
            console.log("response.data", response.data);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    } else {
      setSubmitButtonPressed(false);
    }
  };

  return (
    <div>
      <Navbar
        expand="lg"
        // className=" backgroundColor-white"
        style={{
          backgroundColor: "#FBFBFB",
          //   backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          {/*<Button
            style={{
              background: "none",
              color: "#1752aa",
              border: "none",
              left: -20,
            }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>*/}
          <Navbar.Brand href="/">
            <img
              style={{ width: "120px" }}
              src="/Img/DhobiCart.png"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-home" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                <i
                  className="pi pi-power-off"
                  style={{
                    fontSize: "2rem",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <div style={{ textAlign: "center", marginBottom: 30 }}>
          <h3>Package Garment Management</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "red",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // marginLeft: 25,
                // backgroundColor: "green",
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "280px",
                  // height: "280px",
                  backgroundColor: "#fff",
                  padding: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  margin: 5,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <h4
                  style={{
                    textAlign: "center",
                    color: "#1752AA",
                    margin: 20,
                  }}
                >
                  Package List
                </h4>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "126px", marginBottom: 10 }}
                    src="/Img/box.png"
                    alt="logo"
                  />
                </div>
                <Dropdown
                  value={input.priceId}
                  style={{ width: "100%" }}
                  options={brandPackageList}
                  onChange={handlePackageChange}
                  placeholder="Select a Package List"
                />
                <div className="text-danger mt-1">{errors.priceId}</div>
              </div>

              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "280px",
                  // height: "280px",
                  backgroundColor: "#fff",
                  padding: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  margin: 5,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <h4
                  style={{
                    textAlign: "center",
                    color: "#1752AA",
                    margin: 20,
                  }}
                >
                  Service Unit
                </h4>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "126px", marginBottom: 10 }}
                    src="/Img/services.png"
                    alt="logo"
                  />
                </div>
                <InputText
                  type="text"
                  style={{ width: "100%" }}
                  id="serviceUnit"
                  name="serviceUnit"
                  value={selectedPackage?.serviceUnit}
                  readonly
                />
                <div className="text-danger mt-1">{errors.serviceUnit}</div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: 25,
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "280px",
                  // height: "280px",
                  backgroundColor: "#fff",
                  padding: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                }}
              >
                <h4
                  style={{
                    width: "150px",
                    textAlign: "center",
                    color: "#1752AA",
                    margin: 20,
                  }}
                >
                  App Service
                </h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "126px", marginBottom: 10 }}
                    src="/Img/quantity.png"
                    alt="logo"
                  />
                </div>

                <InputText
                  type="text"
                  style={{ width: "100%" }}
                  id="appService"
                  name="appService"
                  value={selectedPackage?.appService}
                  readonly
                />
                <div className="text-danger mt-1">{errors.appServiceList}</div>
                <button
                  style={{ position: "absolute", bottom: -130 }}
                  type="submit"
                  className="move-btn-a move-btn-su"
                  disabled={submitButtonPressed}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {/*<div className="col-md-12 text-center">
            <button
              type="submit"
              className="move-btn-a move-btn-su"
              disabled={submitButtonPressed}
            >
              Submit
            </button>
          </div>*/}
        </form>
      </Container>
      <footer style={{ backgroundColor: "#FBFBFB" }}>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="flex social-media-div" style={{ marginLeft: 30 }}>
                <Link
                  to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
                  target="_blank"
                >
                  <i
                    className="pi pi-youtube"
                    style={{
                      fontSize: "2rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.instagram.com/dhobicart/?hl=en"
                  target="_blank"
                >
                  <i
                    className="pi pi-instagram"
                    style={{
                      fontSize: "2rem",
                      color: "#d62976",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/71974228/admin/dashboard/"
                  target="_blank"
                >
                  <i
                    className="pi pi-linkedin"
                    style={{
                      fontSize: "2rem",
                      color: "#0072b1",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.facebook.com/oneindiatechno"
                  target="_blank"
                >
                  <i
                    className="pi pi-facebook"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
                  target="_blank"
                >
                  <i
                    className="pi pi-android"
                    style={{
                      fontSize: "2rem",
                      color: "#a4c639",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link to="tel:+91 9319518337" target="_blank">
                  <i
                    className="pi pi-phone"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
              </Nav>
            </Col>
            {/*<Col>
              <Link
                to="https://www.oneindiatech.com/?page_id=75"
                target="_blank"
                style={{ textDecoration: "none", fontSize: 20 }}
              >
                Contact Us!
              </Link>
              <p>Phone : +91 9319518337</p>
            </Col>*/}
          </Row>
          <Row>
            <Col>
              <div className="text-center p-3" style={{ fontSize: 16 }}>
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a className="text-dark" href="https://oneindiatech.com/">
                  One India Technologies Pvt. Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default PackageListSelection;
