import axios from "axios";
import getHeaders from "../constants/headers";
import { PRICElIST_BASE_URL } from "./BaseURL";

export const getBrandAppService = (brandId) => {
  return axios({
    method: "get",
    url:
      PRICElIST_BASE_URL + "/api/secure/v1/brand/" + brandId + "/app-service",
    headers: getHeaders(),
  });
};

// export const getBrandAppService = () => {
//   return axios({
//     method: "get",
//     url: "https://dhobicart.in/pricelist/api/secure/v1/brand/1/app-service",
//     headers: {
//       Authorization:
//         "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6IkRDT0lUR0VEIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxMCIsImlhdCI6MTcxMDc0MTU3MywiZXhwIjoxNzExMzQ2MzczfQ.VLb1d2mdadm-SH0sazv9SJR2aiQ38AUypl9vmBCLOZC3fs2lqBSIPOEi5BXFIrTTWSgxVf_HQ9JhpTUD-Vmv5g",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Headers": "*",
//     },
//   });
// };

// export const getBrandAppService = () => {
//   let accessToken =
//     "eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6IkRDT0lUR0VEIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxMCIsImlhdCI6MTcxMDc0Nzg4NSwiZXhwIjoxNzExMzUyNjg1fQ.YSl3L8zaQaXa5EbKYBJgMEqwUwMiiAzDg3utaPdYv5WGqEX-q1g1SVNMfAYP9ITky1Xx3ZxUpZ3cGXuVXGJLCQ";
//   return axios({
//   method: "get",
//   url: "https://dhobicart.in/pricelist/api/secure/v1/brand/1/app-service",
//   headers: { Authorization: "Bearer " + accessToken, },
// });
// }

// export const getBrandAppService = (brandId) => {
//   return axios({
//     method: "get",
//     url:
//       PRICElIST_BASE_URL + "/api/secure/v1/brand/" + brandId + "/app-service",
//     headers: getHeaders2(),
//     // headers: {
//     //   'Authorization':
//     //     "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6IkRDT0lUR0VEIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzdWIiOiIxMCIsImlhdCI6MTcxMDc0MTU3MywiZXhwIjoxNzExMzQ2MzczfQ.VLb1d2mdadm-SH0sazv9SJR2aiQ38AUypl9vmBCLOZC3fs2lqBSIPOEi5BXFIrTTWSgxVf_HQ9JhpTUD-Vmv5g",
//     // },
//   });
// };
