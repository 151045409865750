export const setUserData = ({
  token,
  userProfileData,
  brandProfileData,
  storeData,
}) => {
  localStorage.setItem("adminToken", token);
  localStorage.setItem("userProfileData", JSON.stringify(userProfileData));
  localStorage.setItem("brandProfileData", JSON.stringify(brandProfileData));
  localStorage.setItem("storeData", JSON.stringify(storeData));
};

export const getUserData = () => {
  const token = localStorage.getItem("adminToken");
  const userProfileData =
    JSON.parse(localStorage.getItem("userProfileData")) || {};
  const brandProfileData =
    JSON.parse(localStorage.getItem("brandProfileData")) || {};
  const storeData = JSON.parse(localStorage.getItem("storeData")) || {};
  return { token, userProfileData, brandProfileData, storeData };
  // return { token};
};

export const removeUserData = () => {
  localStorage.removeItem("adminToken");
  localStorage.removeItem("userProfileData");
  localStorage.removeItem("brandProfileData");
  localStorage.removeItem("storeData");
};
