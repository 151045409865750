export const formatDate = (dateString) => {
  const parsedDate = new Date(dateString.replace("Z", ""));

  return parsedDate.toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour12: true,
  });
};
