import React from 'react';
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';

const MainLayout = () => {
    const { isSidebarOpen } = useSelector((state) => state.auth);

    return (
        <div className={isSidebarOpen ? "" : "toggle-sidebar"}>
            {/*<Header />
    <SideBar userProfileData={userProfileData}/>*/}
            <Outlet />
            {/*<Footer />*/}
        </div>
    )
};

export default MainLayout;
