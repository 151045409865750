
import axios from "axios";
import getHeaders from "../constants/headers";
import { PRICElIST_BASE_URL } from "./BaseURL";

export const getServiceUnit = () => {
  return axios({
    method: "get",
    url:
      PRICElIST_BASE_URL +
      "/api/secure/v1/service-unit",
    headers: getHeaders(),
  });
};
