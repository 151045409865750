import React, { useEffect } from 'react'
import { Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const GarmentTag = () => {
     const { state } = useLocation();
    const print = () => {
        window.print();
    }

    useEffect(() => {
      print();
    }, [state?.type]);
    

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {state?.qrCodeImages?.map((item, index) => (
        <Image
         
          src={`data:image/jpeg;base64,${item}`}
        />
      ))}
    </div>
  );
}

export default GarmentTag